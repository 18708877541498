import React from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";

import Layout from "../components/layout";
import NewsletterSignup from "../components/newsletterSignup";
import CallToActionGeneric from "../components/callToActionGeneric";

class TermsOfUse extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <Helmet
          htmlAttributes={{ lang: "en" }}
          title="Terms of Use - Baton Rouge | Lewis Mohr"
          meta={[
            {
              name: "description",
              content:
                "By using this Website, you represent and warrant that you are of legal age to form a binding contract with Company and meet all of the foregoing eligibility requirements. If you do not meet all of the requirements, you must not access or use the Website."
            }
          ]}
        />
        <div className="site-body page-content">
          <br />
          <br />
          <h1>Terms of Use</h1>
          <p>Last Updated: Oct, 2016</p>
          <h3>Acceptance of the Conditions of Use</h3>
          <p>
            The following terms and conditions of use, together with any
            documents expressly incorporated herein (collectively, the
            “Conditions of Use”), govern your access to and use of
            www.mohragency.com, including any content, functionality, and
            services offered on or through www.mohragency.com (the “Website”),
            whether as a customer who participates in Lewis Mohr Real Estate
            &amp; Insurance Agency, LLC (“Company”) services (“Client”) or other
            users of the Website who are not Clients (“Visitors”, and together
            with Clients, “Users”, “you”, or “your”).
            <br />
            <br /> Please read the Conditions of Use carefully before you start
            to use the Website.{" "}
            <strong>
              By using the Website, you accept and agree to be bound and abide
              by these Conditions of Use
            </strong>
            . If you do not want to agree to these Conditions of Use, you must
            not access or use the Website. This Website is offered and available
            to users who are 18 years of age or older and reside in the United
            States or any of its territories or possessions. By using this
            Website, you represent and warrant that you are of legal age to form
            a binding contract with Company and meet all of the foregoing
            eligibility requirements. If you do not meet all of the
            requirements, you must not access or use the Website.
            <br />
            <br />
          </p>
          <h3>Changes to the Conditions of Use and Website</h3>
          <p>
            Company may revise and update these Conditions of Use from time to
            time in its sole discretion. All changes are effective immediately
            when posted and apply to all access to and use of the Website
            thereafter. Your continued use of the Website following the posting
            of revised Conditions of Use means that you accept and agree to the
            changes. You are expected to check this page from time to time so
            you are aware of any changes, as they are binding on you.
            <br />
            <br /> Company may update the content on the Website from time to
            time, but its content is not necessarily complete or up-to-date. Any
            of the material on the Website may be out of date at any given time,
            and Company is under no obligation to update such material.
            <br />
            <br />
          </p>
          <h3>Accessing the Website and Account Security</h3>
          <p>
            Company reserves the right to withdraw or amend the Website, and any
            service or material provided on the Website, in Company’s sole
            discretion and without notice to you. Company will not be liable, if
            for any reason, all or any part of the Website is unavailable at any
            time or for any period. From time to time, Company may restrict
            access to some parts of the Website, or the entire Website, to
            Users, including Visitors and/or Clients.
            <br />
            <br /> You are responsible for making all arrangements necessary for
            you to have access to the Website and ensuring that all persons who
            access the Website through your internet connection are aware of
            these Conditions of Use and comply with them.
            <br />
            <br /> To access the Website or some of the resources it offers, you
            may be asked to provide certain registration details or other
            information. It is a condition of your use of the Website that all
            the information you provide on the Website is truthful, accurate,
            current, and complete.
            <br />
            <br /> If you choose, or are provided with, a user name, password,
            or any other piece of information as part of the Website security
            procedures, you must treat such information as confidential, and you
            must not disclose it to any other person or entity. You also
            acknowledge that your account is personal to you and agree not to
            provide any other person with access to the Website or portions of
            it using your user name, password, or other security information.
            You agree to notify us immediately of any unauthorized access to or
            use of your user name or password or any other breach of security.
            You also agree to ensure that you exit from your account at the end
            of each session. You should use particular caution when accessing
            your account from a public or shared computer so that others are not
            able to view or record your password or other personal information.
            <br />
            <br /> Company has the right to disable any user name, password, or
            other identifier, whether chosen by you or provided by Company, at
            any time, in Company’s sole discretion for any or no reason,
            including, if, in Company’s opinion, you have violated any provision
            of these Conditions of Use.
            <br />
            <br />
          </p>
          <h3>Intellectual Property Rights</h3>
          <p>
            The Website and its entire contents, features, and functionality
            (including but not limited to all information, software, text,
            displays, images, video, audio, and the design, selection, and
            arrangement thereof), are owned by Company, its licensors, or other
            providers of such material and are protected by United States and
            international copyright, trademark, patent, trade secret, and other
            intellectual property or proprietary laws.
            <br />
            <br /> The Lewis Mohr Real Estate &amp; Insurance Agency, LLC, the
            terms Lewis Mohr, the Lewis Mohr Real Estate &amp; Insurance Agency,
            LLC, logo and all related names, logos, product names, service
            names, designs, and slogans are trademarks of Company or its
            affiliates or licensors. You must not use such marks without the
            prior written permission of Company. All other names, logos, product
            names, service names, designs, and slogans on the Website are the
            trademarks of their respective owners.
            <br />
            <br /> These Conditions of Use permit you to use the Website for
            your personal, non-commercial use only. You must not reproduce,
            distribute, modify, create derivative works of, publicly display,
            publicly perform, republish, download, store, or transmit any of the
            material on the Website, except as follows:
            <br />
            <br />
            <ul>
              <li>
                Your computer may temporarily store copies of such materials in
                RAM incidental to your accessing and viewing those materials.
              </li>
              <li>
                You may store files that are automatically cached by your Web
                browser for display enhancement purposes.
              </li>
              <li>
                You may print or download one copy of a reasonable number of
                pages of the Website for your own personal, non-commercial use
                and not for further reproduction, publication, or distribution.
              </li>
            </ul>
            <br />
            <br /> You must not:
            <br />
            <br />
            <ul>
              <li>Modify copies of any materials from this site.</li>
              <li>
                Use any illustrations, photographs, video or audio sequences, or
                any graphics separately from the accompanying text.
              </li>
              <li>
                Delete or alter any copyright, trademark, or other proprietary
                rights notices from copies of materials from this site.
              </li>
              <li>
                Access or use for any commercial purposes any part of the
                Website or any services or materials available through the
                Website.
              </li>
            </ul>
            <br />
            <br /> No right, title, or interest in or to the Website or any
            content on the Website is transferred to you, and all rights not
            expressly granted are reserved by Company. Any use of the Website
            not expressly permitted by these Conditions of Use is a breach of
            these Conditions of Use and may violate copyright, trademark, and
            other laws.
            <br />
            <br />
          </p>
          <h3>Prohibited Uses</h3>
          <p>
            You may use the Website only for lawful purposes and in accordance
            with the Conditions of Use. You agree not to:
            <ul>
              <li>
                Use the Website in any way that violates any applicable federal,
                state, local, or international law or regulation.
              </li>
              <li>
                Use the Website to engage in conduct which is defamatory,
                libelous, threatening, or harassing.
              </li>
              <li>
                Use the Website to engage in conduct that infringes on a third
                party’s intellectual property or other proprietary rights.
              </li>
              <li>
                Use the Website to transmit, or procure the sending of, any
                advertising or promotional material, including any “junk mail”,
                “chain letter”, or “spam” or any other similar solicitation.
              </li>
              <li>
                Impersonate or attempt to impersonate Company, any Company
                employee, another User, or any other person or entity
                (including, without limitation, by using e-mail addresses
                associated with any of the foregoing.
              </li>
              <li>
                Use the Website to engage in any other conduct that restricts or
                inhibits anyone’s use or enjoyment of the Website, or which, as
                determined by Company, may harm Company or Users of the Website
                or expose any of the foregoing to liability.
              </li>
              <li>
                Use the Website in any manner that could disable, overburden,
                damage, or impair the Website or interfere with any other
                party’s use of the Website, including their ability to engage in
                real time activities through the Website.
              </li>
              <li>
                Use any robot, spider, or other automatic device, process, or
                means to access the Website for any purpose, including
                monitoring or copying any of the material on the Website.
              </li>
              <li>
                Use any manual process to monitor or copy any of the material on
                the Website or for any other unauthorized purpose without
                Company’s prior written consent.
              </li>
              <li>
                Use any device, software, or routine that interferes with the
                proper working of the Website.
              </li>
              <li>
                Introduce any viruses, trojan horses, worms, logic bombs, or
                other material which is malicious or technologically harmful.
              </li>
              <li>
                Attempt to gain unauthorized access to, interfere with, damage
                or disrupt any parts of the Website, the server on which the
                Website is stored, or any server, computer, or database
                connected to the Website.
              </li>
              <li>
                Attack the Website via a denial-of-service attack or a
                distributed denial-of-service attack.
              </li>
              <li>
                Otherwise attempt to interfere with the proper working of the
                Website.
              </li>
              <li>
                Cause the Website or portions of it to be displayed, or appear
                to be displayed by, for example, framing, deep linking, or
                in-line linking, on any other site.
              </li>
            </ul>
            <br />
            <br />
          </p>
          <h3>Monitoring and Enforcement; Termination</h3>
          <p>
            Company has the right to take appropriate legal action, including
            without limitation, referral to law enforcement, for any illegal or
            unauthorized use of the Website. Company also has the right to
            terminate or suspend your access to all or part of the Website for
            any or no reason, including, without limitation, any violation of
            the Conditions of Use.
            <br />
            <br /> Without limiting the foregoing, Company has the right to
            fully cooperate with any law enforcement authorities or court order
            requesting or directing us to disclose the identity or other
            information of accessing and/or using the Website. YOU WAIVE AND
            HOLD HARMLESS COMPANY AND ITS AFFILIATES, LICENSEES, AND SERVICE
            PROVIDERS FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY ANY OF
            THE FOREGOING PARTIES DURING OR AS A RESULT OF ITS INVESTIGATIONS
            AND FROM ANY ACTIONS TAKEN AS A CONSEQUENCE OF INVESTIGATIONS BY
            EITHER THE FOREGOING PARTIES OR LAW ENFORCEMENT AUTHORITIES.
            <br />
            <br />
          </p>
          <h3>Availability of Services and Reliance on Information Posted</h3>
          <p>
            The information presented on or through the Website is made
            available solely for general information purposes. Company does not
            warrant the accuracy, completeness, or usefulness of this
            information, including any calculators or quotes. Any reliance you
            place on such information is strictly at your own risk. Company
            disclaims all liability and responsibility arising from any reliance
            placed on such materials by you or any other visitor to the Website,
            or by anyone who may be informed of any of the Website’s contents.
            <br />
            <br /> This Website may include content, including advertising,
            provided by third parties. All statements and/or opinions expressed
            in these materials, and all articles and responses to questions and
            other content, other than the content provided by Company, are
            solely the opinions and the responsibility of the person or entity
            providing those materials. These third party materials do not
            necessarily reflect the opinion of Company. Company is not
            responsible, or liable to you or any third party, for the content or
            accuracy of any materials provided by any third parties.
            <br />
            <br /> Company does business in certain states in the United States.
            Not all of the services described on this site are available in all
            states. You may not be eligible for all products and services.
            Company reserves the right to determine your eligibility as
            permitted by law.
            <br />
            <br /> Any quotes generated by the Website are estimates based on
            the information you provide and are not a contract, binder, or
            agreement to extend insurance coverage. Descriptions of insurance
            coverage provided on the Website are only general descriptions and
            are not a statement of contract. To obtain insurance coverage, you
            must submit an application and all applications are subject to
            underwriting approval. Availability and coverage varies state to
            state.
            <br />
            <br /> If Website contains an interactive feature allowing the
            reporting of an insurance claim for Clients, any submission of a
            report does not commit Company to coverage for the loss. The loss
            reported and any information provided by you is subject to review
            and verification. Company reserves the right to request additional
            information regarding any reported insurance claim. <br />
            <br />
          </p>
          <h3>Online Purchases and Other Terms and Conditions</h3>
          <p>
            Certain products or services may be subject to specific terms,
            conditions, representations, or agreements. All purchases through
            the Website or other transactions for the sale of services formed
            through the Website or as a result of visits made by you to the
            Website are governed by the agreement(s), such as insurance
            policies, you agree to at the time of the purchase or transaction
            (hereafter referred to as “Service Agreements. In the event of a
            conflict between a Service Agreement and the Conditions of Use, the
            terms of the Service Agreement will control the rights and
            obligations of the applicable Clients.
            <br />
            <br /> Additional terms and conditions may also apply to specific
            portions, services, or features of the Website. All such additional
            terms and conditions are hereby incorporated by this reference into
            the Conditions of Use.
            <br />
            <br />
          </p>
          <h3>Links to Third Party Sites</h3>
          <p>
            The Website may contain links to other sites and resources provided
            by third parties; these links are provided for your convenience
            only. Company has no control over the contents of those sites or
            resources, and accepts no responsibility for them or for any loss or
            damage that may arise from your use of them. If you decide to access
            any of the third party websites linked to this Website, you do so
            entirely at your own risk and subject to the terms and conditions of
            use for such websites.
            <br />
            <br />
          </p>
          <h3>Geographic Restrictions</h3>
          <p>
            Company is based in the state of Louisiana in the United States.
            Company provides the Website for use only by persons located in the
            United States. Company makes no claims that the Website or any of
            its content is accessible or appropriate outside of the United
            States. Access to the Website may not be legal by certain persons or
            in certain countries. If you access the Website from outside the
            United States, you do so on your own initiative and are responsible
            for compliance with local laws.
            <br />
            <br />
          </p>
          <h3>Disclaimer of Warranties</h3>
          <p>
            You understand that Company cannot and does not guarantee or warrant
            that files available for downloading from the internet or the
            Website will be free from viruses or other destructive code. You are
            responsible for implementing sufficient procedures and checkpoints
            to satisfy your particular requirements for anti-virus protection
            and accuracy of data input and output, and for maintaining a means
            external to our site for any reconstruction of any lost data.
            COMPANY WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A
            DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES, OR OTHER
            TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER
            EQUIPMENT, COMPUTER PROGRAMS, DATA, OR OTHER PROPRIETARY MATERIAL
            DUE TO YOUR USE OF THE WEBSITE OR ANY SERVICES OR ITEMS OBTAINED
            THROUGH THE WEBSITE OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON
            THE WEBSITE OR ON ANY WEBSITE LINKED TO THE WEBSITE.
            <br />
            <br /> YOUR USE OF THE WEBSITE, ITS CONTENTS, AND ANY SERVICES OR
            ITEMS OBTAINED THROUGH THE WEBSITE IS AT YOUR OWN RISK. THE WEBSITE,
            ITS CONTENTS, AND ANY INTERACTIVE FEATURES ON THE WEBSITE ARE
            PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS, WITHOUT ANY
            WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, NEITHER COMPANY
            NOR ANY PERSON ASSOCIATED WITH COMPANY MAKES ANY WARRANTY OR
            REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY,
            RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE WEBSITE.
            WITHOUT LIMITING THE FOREGOING, NEITHER COMPANY NOR ANYONE
            ASSOCIATED WITH COMPANY REPRESENTS OR WARRANTS THAT THE WEBSITE, ITS
            CONTENTS OR ANY INTERACTIVE FEATURES ON THE WEBSITE WILL BE
            ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL
            BE CORRECTED, THAT THE WEBSITE OR THE SERVER THAT MAKES IT AVAILABLE
            ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE WEBSITE
            OR ANY INTERACTIVE FEATURES ON THE WEBSITE WILL OTHERWISE MEET YOUR
            NEEDS OR EXPECTATIONS.
            <br />
            <br /> COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER
            EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE, INCLUDING BUT NOT
            LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, AND
            FITNESS FOR A PARTICULAR PURPOSE.
            <br />
            <br /> THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE
            EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
            <br />
            <br />
          </p>
          <h3>Limitation of Liability</h3>
          <p>
            IN NO EVENT WILL COMPANY, ITS AFFILIATES, OR THEIR LICENSORS,
            SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, OR DIRECTORS BE
            LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT
            OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE WEBSITE,
            ANY WEBSITES LINKED TO THE WEBSITE, ANY CONTENT ON THE WEBSITE OR
            SUCH OTHER WEBSITES, OR ANY INTERACTIVE FEATURES ON THE WEBSITE OR
            SUCH OTHER WEBSITES, INCLUDING ANY DIRECT, INDIRECT, SPECIAL,
            INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING BUT NOT
            LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS,
            LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED
            SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER
            CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT, OR
            OTHERWISE, EVEN IF FORESEEABLE. <br />
            <br /> THE FOREGOING DOES NOT AFFECT ANY LIABILITY WHICH CANNOT BE
            EXCLUDED OR LIMITED UNDER APPLICABLE LAW. IN ANY EVENT, COMPANY’S
            LIABILITY TO YOU FOR ALL LOSSES, DAMAGES, INJURIES, AND CLAIMS OF
            ANY KIND, WHETHER THE DAMAGES ARE CLAIMED UNDER THE TERMS OF A
            CONTRACT, OR THEY ARE CLAIMED TO BE CAUSED BY NEGLIGENCE OR OTHER
            WRONGFUL CONDUCT OR ANY OTHER LEGAL THEORY, WILL NOT BE GREATER THAN
            THE AMOUNT YOU HAVE PAID TO ACCESS COMPANY’S WEBSITE.
            <br />
            <br />
          </p>
          <h3>Indemnification</h3>
          <p>
            You agree to defend, indemnify, and hold harmless Company, its
            affiliates, licensors, and service providers, and its and their
            respective officers, directors, employees, contractors, agents,
            licensors, suppliers, successors, and assigns from and against any
            claims, liabilities, damages, judgments, awards, losses, costs,
            expenses, or fees (including reasonable attorneys’ fees) arising out
            of or relating to your violation of these Conditions of Use or your
            use of the Website, including, but not limited to, any materials you
            upload and any use of the Website’s content, services and products
            other than as expressly authorized in the Conditions of Use or your
            use of any information obtained from the Website.
            <br />
            <br />
          </p>
          <h3>Governing Law and Jurisdiction</h3>
          <p>
            All matters relating to the Website and the Conditions of Use and
            any dispute or claim arising therefrom or related thereto (in each
            case, including non-contractual disputes or claims), will be
            governed by and construed in accordance with the internal laws of
            the State of Louisiana, without giving effect to and choice or
            conflict of law provision or rule, whether of the State of Louisiana
            or any other jurisdiction.
            <br />
            <br /> Any legal suit, action, or proceeding arising out of, or
            related to, the Conditions of Use or the Website will be instituted
            exclusively in the federal courts of the United States or the courts
            of the State of Louisiana, in each case located in the City of Baton
            Rouge and Parish of East Baton Rouge. You waive any and all
            objections to the exercise of jurisdiction over you by such courts
            and to venue in such courts.
            <br />
            <br />
          </p>
          <h3>Waiver and Severability</h3>
          <p>
            No waiver, by Company, of any term or condition set forth in the
            Conditions of Use will be deemed a further or continuing waiver of
            such term or condition or a Waiver of any other term or condition.
            Any failure of Company to assert a right or provision under the
            Conditions of Use will not constitute a waiver of such right or
            provision.
            <br />
            <br /> If any provision of the Conditions of Use is held by a court
            or other tribunal of competent jurisdiction to be invalid, illegal,
            or unenforceable for any reason, such provision will be eliminated
            or limited to the minimum extent such that the remaining provisions
            of the Conditions of Use will continue in full force and effect.
            <br />
            <br />
          </p>
          <h3>Entire Agreement</h3>
          <p>
            The Conditions of Use and any Service Agreements constitute the sole
            and entire agreement between you and Company with respect to the
            Website and supersede all prior and contemporaneous understandings,
            agreements, representations, and warranties, both written and oral,
            with respect to the Website.
            <br />
            <br />
          </p>
        </div>
        <CallToActionGeneric bg={this.props.data.callToActionBg} />
        <NewsletterSignup />
      </Layout>
    );
  }
}

export default TermsOfUse;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    callToActionBg: file(relativePath: { eq: "bg-cta-28.jpg" }) {
      ...mainBg
    }
  }
`;
